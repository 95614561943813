<template>
    <a-card>
        <a-page-header
            title='广告创意管理'
        />
        
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row :gutter="[5,0]">
                <a-col :span="8">
                    <a-form-model-item label="查询周期">
                        <a-range-picker
                            v-model="pickerTime"
                            value-format="YYYY-MM-DD"
                            :disabled-date="disabledDate"
                            :allow-clear="false"
                            @calendarChange="calendarChange"
                            @openChange="openChange"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告计划ID">
                        <a-input
                            v-model="form.adPlanId"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告计划状态">
                        <a-select
                            v-model="form.adPlanStatus"
                            placeholder="请选择"
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in planState" :key="item.code">{{ item.msg }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告创意状态">
                        <a-select
                            mode="multiple"
                            v-model="form.adCreativeStatusList"
                            placeholder="请选择"
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in creativeState" :key="item.code">{{ item.msg }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告计划品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告计划车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :pagination='false'
            :scroll="{x: 2000}"
            bordered
        >
            <div
                slot="cover"
                slot-scope="text, record"
            >
                <span v-if="record.imageUrl">
                    <img :src="record.imageUrl" class="cover" @click="handlePreviewOpen(record)">
                </span>
                <span v-else>-</span>
            </div>
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <base-button
                    :type="'link'"
                    :title="'详情'"
                    @onClickBtn="openDetailDrawer(record, 1)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'操作日志'"
                    @onClickBtn="openDetailDrawer(record, 2)"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />


        <a-drawer
            placement="right"
            :closable="false"
            :visible="detailVisible"
            :width="1000"
            @close="closeDetailDrawer"
        >
            <a-tabs v-model="activeIndex">
                <a-tab-pane :key="1" tab="详情">
                    <div class="box">
                        <div class="title">广告平台信息</div>
                        <div class="row">
                            <div class="name">视频ID：</div>
                            <div class="detail">{{ dataDetail.adVideoId || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">图片ID：</div>
                            <div class="detail">{{ dataDetail.imageId || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告创意创建时间：</div>
                            <div class="detail">{{ dataDetail.creativeCreateTime || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告创意修改时间：</div>
                            <div class="detail">{{ dataDetail.creativeModifyTime || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告计划ID：</div>
                            <div class="detail">{{ dataDetail.adPlanId || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">平台账套ID：</div>
                            <div class="detail">{{ dataDetail.adPlatformAccountId || '-' }}</div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="title">皮皮慧投定义信息</div>
                        <div class="row">
                            <div class="name">广告计划品牌：</div>
                            <div class="detail">{{ dataDetail.adPlanPrincipalName || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告计划车系：</div>
                            <div class="detail">{{ dataDetail.adPlanCarSeriesName || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告计划场景：</div>
                            <div class="detail">{{ dataDetail.customerSceneDesc || '' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告主：</div>
                            <div class="detail">{{ dataDetail.advertiserName || '-' }}</div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="日志">
                    <div style="margin: 20px 0">广告创意：{{ rowData.title || '-' }}</div>
                    <div>广告创意ID：{{ rowData.adCreativeId || '-' }}</div>
                    <a-table
                        :columns="logColumns"
                        :data-source="logsList"
                        :rowKey="(record, index) => index"
                        :pagination='false'
                        bordered
                        style="margin-top: 20px;"
                    ></a-table>
                    <base-pagination
                        :currentPage="paginationLog.current"
                        :pageSize="paginationLog.pageSize"
                        :total="paginationLog.total"
                        @onChange="handlePaginationLogChange"
                        @onShowSizeChange="handlePaginationLogChange"
                    />
                </a-tab-pane>
            </a-tabs>
        </a-drawer>

        <preview-modal
            :visible="previewVisible"
            :preview-src="previewSrc"
            :isAuth="false"
            width="50%"
            @cancel="handlePreviewCancel"
        />
    </a-card>
</template>

<script>
import moment from 'moment'
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo"

export default {
    components: {
        previewModal
    },
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            colSpan: 12,
            form: {
                adPlanId: this.$route.query.adPlanId,
                adCreativeStatusList: [],
            },
            pickerTime: [moment(), moment()],
            selectStartDay: '',
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '巨量开关状态',
                    dataIndex: 'adOptStatusDesc',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告计划状态',
                    dataIndex: 'adPlanStatusDesc',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告创意状态',
                    dataIndex: 'adStatusDesc',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告创意视频/图片',
                    scopedSlots: { customRender: 'cover' },
                },
                {
                    align: 'center',
                    title: '广告创意标题',
                    dataIndex: 'title',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'operation' },
                },
                {
                    align: 'center',
                    title: '消费（元）',
                    dataIndex: 'totalCost',
                    customRender: (text, row, index) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '目标转化数',
                    dataIndex: 'totalConvert',
                    customRender: (text, row, index) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '转化单价（元）',
                    dataIndex: 'convertCost',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '展示数',
                    dataIndex: 'totalShow',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '播放数',
                    dataIndex: 'totalPlay',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击数',
                    dataIndex: 'totalClick',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '展示转化率',
                    dataIndex: 'showConvertRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击率',
                    dataIndex: 'clickRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击转化率',
                    dataIndex: 'clickConvertRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 50,
                total: 0
            },
            planState: [],
            creativeState: [],
            principalList: [],
            seriesList: [],
            selectedRowKeys: [],
            activeIndex: 1,
            detailVisible: false,
            dataDetail: {},
            actionVisible: false,
            rowData: {},
            logsList: [],
            logColumns: [
                {
                    align: 'center',
                    title: '操作时间',
                    dataIndex: 'createTime',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作内容',
                    dataIndex: 'contentTitle',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作前后内容',
                    width: '40%',
                    customRender: (text, row, index) => {
                        let txt = row.contentLog.map(item => {
                            return <div>{item}</div>
                        })
                        return txt
                    }
                },
                {
                    align: 'center',
                    title: '操作人',
                    dataIndex: 'operator',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作IP',
                    dataIndex: 'optIp',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
            ],
            logsLoading: false,
            paginationLog: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            previewVisible: false,
            previewSrc: '',
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.videoStatus == 3,
                    },
                })
            };
        },
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getAdPlanStatusList()
        this.getAdCreativeStatus()
        this.getDataList()
    },
    methods: {
        handlePreviewOpen(record) {
            this.$api.core.materialManage.getUrlByHjkAdVideoId(record.hjkAdVideoId).then((res) => {
                if(res.code == 200) {
                    // window.open(res.data.playUrl, '_blank', 'noreferrer=yes')
                    this.previewVisible = true
                    this.previewSrc = res.data.playUrl
                } else {
                    this.$message.error('获取视频地址失败')
                }
            })
            // if(type == 'url') {
            //     window.open(url)
            // } else {
            //     this.previewVisible = true
            //     this.previewSrc = url
            // }
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
        disabledDate(current) {
            return current > this.$moment(this.selectStartDay).add(31, 'days') || current < this.$moment(this.selectStartDay).subtract(31, 'days')
        },
        calendarChange(dates, dateStrings) {
            console.log(dates, dateStrings)
            this.selectStartDay = dates[0]
        },
        openChange(status) {
            this.selectStartDay = ''
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res.message}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIdList: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res.message}`)
                }
            })
        },
        getAdPlanStatusList() {
            this.$api.core.JLAdvertManage.getAdPlanStatusList().then((res) => {
                if(res.code == 200) {
                    this.planState = res.data
                } else {
                    this.planState = []
                    this.$message.error(`获取计划状态列表失败,${res.message}`)
                }
            })
        },
        getAdCreativeStatus() {
            this.$api.core.JLAdvertManage.getAdCreativeStatus().then((res) => {
                if(res.code == 200) {
                    this.creativeState = res.data
                    this.form.adCreativeStatusList = [this.creativeState[0].code]
                    this.getDataList()
                } else {
                    this.creativeState = []
                    this.$message.error(`获取创意状态列表失败,${res.message}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                adPlanId: this.$route.query.adPlanId,
                adCreativeStatusList: [this.creativeState[0].code]
            }
            this.pickerTime = [moment(), moment()]

            this.pagination.current = 1
            this.pagination.pageSize = 50
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            
            let params = {
                ..._form,
                startTime: `${moment(this.pickerTime[0]).format('YYYY-MM-DD')} 00:00:00`,
                endTime: `${moment(this.pickerTime[1]).format('YYYY-MM-DD')} 23:59:59`,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.JLAdvertManage.getCreativeList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res.message}`)
                }
            })
        },
        openDetailDrawer(record, type) {
            this.detailVisible = true
            this.activeIndex = type
            this.rowData = record

            this.getAdCreativeDetail(record.id)
            this.getActionLogs(record)
        },
        closeDetailDrawer() {
            this.detailVisible = false
            this.dataDetail = {}
            this.rowData = {}
        },
        getAdCreativeDetail(id) {
            this.$api.core.JLAdvertManage.getAdCreativeDetail(id).then((res) => {
                if(res.code == 200) {
                    this.dataDetail = res.data
                } else {
                    this.dataDetail = {}
                    this.$message.error(`获取详情失败,${res.message}`)
                }
            })
        },
        getActionLogs(record) {
            this.logsLoading = true
            let params = {
                adPlatformAccountId: record.adPlatformAccountId,
                objectId: [record.adCreativeId],
                page: this.paginationLog.current,
                pageSize: this.paginationLog.pageSize
            }
            this.$api.core.JLAdvertManage.getActionLogs(params).then((res) => {
                this.logsLoading = false
                if(res.code == 200) {
                    this.logsList = res.data.list || []
                    this.paginationLog.total = res.data.total || 0
                } else {
                    this.logsList = []
                    this.paginationLog.total = 0
                    that.$message.error(`获取操作日志失败，${res.message}`)
                }
            })
        },
        handlePaginationLogChange(current, pageSize) {
            this.paginationLog.current = current
            this.paginationLog.pageSize = pageSize
            this.getActionLogs(this.rowData)

        }
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.mt-40 {
    margin-top: 40px;
}
.txt {
    color: #ccc;
}
.box {
    .title {
        margin: 20px 0 10px;
        font-size: 16px;
        color: #999;
    }
    .row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .name {
        pad: 5px;
        width: 160px;
        text-align: right;
    }
}
.cover {
    width: 60px;
}
</style>